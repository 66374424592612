import Cookie from 'js-cookie'

export function getCookieValue(key: string): string | undefined {
  return Cookie.get(key)
}

export const titleCaseName = (str: string): string => {
  const words = str.toLowerCase().split(' ')
  const result = words.map(val =>
    val.replace(val.charAt(0), val.charAt(0).toUpperCase()),
  )
  return result.join(' ')
}

export const formatCash = (number: number): string => {
  const si = [
    {value: 1, symbol: ''},
    {value: 1e3, symbol: 'k'},
    {value: 1e6, symbol: 'M'},
    {value: 1e9, symbol: 'G'},
    {value: 1e12, symbol: 'T'},
    {value: 1e15, symbol: 'P'},
    {value: 1e18, symbol: 'E'},
  ]
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  let i
  for (i = si.length - 1; i > 0; i--) {
    if (Math.abs(number) >= si[i].value) {
      break
    }
  }
  return (number / si[i].value).toFixed(1).replace(rx, '$1') + si[i].symbol
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const toFormData = (values: {[key: string]: any}): FormData => {
  const formData = new FormData()
  Object.keys(values).forEach(key => {
    formData.append(key, values[key])
  })
  return formData
}
