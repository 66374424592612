/* eslint-disable max-lines-per-function */
import React, {FC, useEffect, useState} from 'react'
import {
  Row,
  Nav,
  NavItem,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormGroup,
  Label,
} from 'reactstrap'
import {IconContext} from 'react-icons'
import {FaAngleUp, FaAngleDown, FaSearch} from 'react-icons/fa'
import AdminLayout from 'components/layouts/admin.component'
import ActionBar from 'components/common/action-bar.component'
import EmptyRes from 'assets/images/empty_response.png'
import EmptyResWebp from 'assets/images/empty_response.webp'
import {
  selectBondsSummary,
  selectBondsSummaryRequesting,
  selectBondsRequesting,
  selectBondsMeta,
  selectBonds,
} from '_redux/bonds/bonds.selectors'
import {theme} from 'AppTheme'
import {useDispatch, useSelector} from 'react-redux'
import {
  fetchBondsSummaryStart,
  fetchBondsStart,
} from '_redux/bonds/all/bonds-all.actions'
import BaseModal from 'components/common/base-modal.component'
import {StyledNavLinkRow} from 'components/common/styles/row.styles'
import LoadingSpinner from 'components/common/loading-spinner.component'
import {StyledChartRow, StyledTableRow} from './styles'
import AddBondFormOverview from './add-form.component'
import DashboardBondMgmtCharts from './charts.component'
import DashboardBondsDetailsTable from './table.component'
import {transformBondsForTable} from './utils'

const dropdownIconValues = {
  color: theme.colors.primary,
  size: '1.4em',
  style: {verticalAlign: 'middle'},
}

const DashboardBondMgmt: FC = () => {
  const [currentBondTab, setCurrentBondTab] = useState('bond')
  const [openAddModal, setOpenAddModal] = useState(false)
  const [openTagDropdown, setOpenTagDropdown] = useState(false)
  const [openStatusDropdown, setOpenStatusDropdown] = useState(false)
  const [openFilterDropdown, setOpenFilterDropdown] = useState(false)
  const [openSortDropdown, setOpenSortDropdown] = useState(false)
  const [currentBondsPage, setCurrentBondsPage] = useState(1)
  const toggleOpenTagDropdown = () => {
    setOpenTagDropdown(prev => !prev)
  }
  const toggleOpenStatusDropdown = () => {
    setOpenStatusDropdown(prev => !prev)
  }
  const toggleOpenFilterDropdown = () => {
    setOpenFilterDropdown(prev => !prev)
  }
  const toggleOpenSortDropdown = () => {
    setOpenSortDropdown(prev => !prev)
  }
  const toggleCurrentBondTab = (tab: string) => {
    if (currentBondTab !== tab) setCurrentBondTab(tab)
  }
  const toggleOpenAddModal = () => {
    setOpenAddModal(prev => !prev)
  }
  const closeAddModal = () => {
    setOpenAddModal(false)
  }

  const dispatch = useDispatch()
  const summaryRequesting = useSelector(selectBondsSummaryRequesting)
  const bondsSummary = useSelector(selectBondsSummary)
  const bondsRequesting = useSelector(selectBondsRequesting)
  const {pages: bondsPages, total} = useSelector(selectBondsMeta)
  const bonds = useSelector(selectBonds)

  const loadMoreBonds = (): void => {
    setCurrentBondsPage(currentBondsPage + 1)
  }

  useEffect(() => {
    dispatch(fetchBondsSummaryStart())
  }, [dispatch])

  useEffect(() => {
    if (!total || currentBondsPage > 1)
      dispatch(fetchBondsStart(currentBondsPage))
  }, [total, currentBondsPage, dispatch])

  const renderNavLinks = () => {
    return ['Bond', 'Text'].map(tab => {
      return (
        <NavItem key={tab}>
          <p
            className={`nav-link mb-0 ${tab === 'Bond' ? 'pl-0' : ''} ${
              currentBondTab === tab.toLowerCase() ? 'active' : ''
            }`}
            onClick={() => toggleCurrentBondTab(tab.toLowerCase())}
          >
            {tab}
          </p>
        </NavItem>
      )
    })
  }

  const transformedBonds = transformBondsForTable(bonds)

  return (
    <AdminLayout pageTitle="Bond Management">
      <Row>
        <ActionBar
          onAddClick={toggleOpenAddModal}
          headerText="Bond Management"
        />
      </Row>
      <StyledNavLinkRow className="border-bottom mb-3">
        <Nav>{renderNavLinks()}</Nav>
      </StyledNavLinkRow>
      <StyledChartRow className="py-3 d-flex justify-content-around border rounded">
        <h5 className="w-100 pl-3 pb-4 font-weight-bold h5">Overview</h5>
        <DashboardBondMgmtCharts
          loading={summaryRequesting}
          bondsSummary={bondsSummary}
        />
      </StyledChartRow>
      <BaseModal
        isOpen={openAddModal}
        toggle={toggleOpenAddModal}
        title="Create Bond"
      >
        <AddBondFormOverview toggleModal={closeAddModal} />
      </BaseModal>
      {bonds.length ? (
        <StyledTableRow className="py-3">
          <Col sm="12" className="border rounded">
            <div className="font-weight-bold py-3 border-bottom">
              Bond details
            </div>
            <div className="d-flex justify-content-between align-items-center py-2 border-bottom">
              <div className="flex-fill mr-5">
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaSearch />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Search" />
                </InputGroup>
              </div>
              <IconContext.Provider value={dropdownIconValues}>
                <div>
                  <Dropdown
                    isOpen={openTagDropdown}
                    toggle={toggleOpenTagDropdown}
                    className="d-inline-block"
                  >
                    <DropdownToggle className="font-weight-bold" color="white">
                      Tagged With&nbsp;
                      {openTagDropdown ? <FaAngleUp /> : <FaAngleDown />}
                    </DropdownToggle>
                    <DropdownMenu className="px-2">
                      <DropdownItem header>Enter Tag</DropdownItem>
                      <DropdownItem divider />
                      <Input />
                    </DropdownMenu>
                  </Dropdown>
                  <Dropdown
                    isOpen={openStatusDropdown}
                    toggle={toggleOpenStatusDropdown}
                    className="d-inline-block"
                  >
                    <DropdownToggle className="font-weight-bold" color="white">
                      Status&nbsp;
                      {openStatusDropdown ? <FaAngleUp /> : <FaAngleDown />}
                    </DropdownToggle>
                    <DropdownMenu className="px-2">
                      <DropdownItem>All</DropdownItem>
                      <DropdownItem>Active</DropdownItem>
                      <DropdownItem>Inactive</DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                  <Dropdown
                    isOpen={openFilterDropdown}
                    toggle={toggleOpenFilterDropdown}
                    className="d-inline-block"
                  >
                    <DropdownToggle className="font-weight-bold" color="white">
                      Filter&nbsp;
                      {openFilterDropdown ? <FaAngleUp /> : <FaAngleDown />}
                    </DropdownToggle>
                    <DropdownMenu className="px-2">
                      <DropdownItem header>Enter text</DropdownItem>
                      <DropdownItem divider />
                      <Input />
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </IconContext.Provider>
            </div>
            <div className="d-flex justify-content-between align-items-center py-3">
              <div>
                <FormGroup className="d-inline-block mr-3" check>
                  <Label check>
                    <Input type="checkbox" /> Select all
                  </Label>
                </FormGroup>
                <span>
                  Showing <strong>{transformedBonds.length}</strong> of{' '}
                  <strong>{total}</strong> accounts
                </span>
              </div>
              <IconContext.Provider value={dropdownIconValues}>
                <div>
                  <Dropdown
                    isOpen={openSortDropdown}
                    toggle={toggleOpenSortDropdown}
                    className="d-inline-block"
                  >
                    <DropdownToggle color="white">
                      Sort by: Last update (newest first)&nbsp;
                      {openStatusDropdown ? <FaAngleUp /> : <FaAngleDown />}
                    </DropdownToggle>
                    <DropdownMenu className="px-2">
                      <DropdownItem>Newest first</DropdownItem>
                      <DropdownItem>Newest last</DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </IconContext.Provider>
            </div>
            <div>
              <DashboardBondsDetailsTable
                headers={[
                  '',
                  '',
                  'Bond',
                  'Location',
                  'Status',
                  'Start date',
                  'End date',
                  'Members',
                  'Unit price',
                  'Units',
                  'Returns',
                ]}
                loading={bondsRequesting}
                page={currentBondsPage}
                body={transformedBonds}
                hasMore={currentBondsPage < bondsPages}
                loadMore={loadMoreBonds}
              />
            </div>
          </Col>
        </StyledTableRow>
      ) : (
        <Row className="w-50 mx-auto my-auto">
          {bondsRequesting ? (
            <div className="mx-auto py-5">
              <LoadingSpinner />
            </div>
          ) : (
            <>
              <picture className="text-center">
                <source srcSet={EmptyResWebp} type="image/webp" />
                <source srcSet={EmptyRes} type="image/png" />
                <img
                  src={EmptyRes}
                  alt="Empty response"
                  className="w-50 py-3 mx-auto"
                />
              </picture>
              <h4 className="text-center mx-auto">No information to display</h4>
            </>
          )}
        </Row>
      )}
    </AdminLayout>
  )
}

export default DashboardBondMgmt
