/* eslint-disable no-new */
import React, {useRef, useEffect, FC} from 'react'
import Chart from 'chart.js'
import {ChartProps} from './types'

const VerticalBarChart: FC<ChartProps> = ({datasets, labels}) => {
  const ref = useRef<HTMLCanvasElement | null>(null)
  useEffect(() => {
    if (ref.current) {
      new Chart(ref.current, {
        type: 'bar',
        data: {
          labels,
          datasets,
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            yAxes: [
              {
                ticks: {
                  min: 0,
                  max: 10000,
                },
              },
            ],
          },
          layout: {
            padding: {
              top: 5,
              left: 15,
              right: 15,
              bottom: 15,
            },
          },
        },
      })
    }
  }, [datasets, labels])
  return (
    <canvas
      ref={ref}
      width="400"
      height="400"
      aria-label="Bar Chart displaying data"
      role="img"
    >
      <span>Your browser does not support the canvas element.</span>
    </canvas>
  )
}
export default VerticalBarChart
