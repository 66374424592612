import styled from 'styled-components/macro'
import {Row} from 'reactstrap'

export const StyledTableRow = styled(Row)`
  & table {
    font-size: 13px;

    & .custom-control-input:checked ~ .custom-control-label::before {
      border-color: ${(props): string => `${props.theme.colors.primary}`};
      background-color: ${(props): string => `${props.theme.colors.primary}`};
    }
  }

  .card.card-body {
    height: 470px;
    overflow-y: scroll;
  }
`
