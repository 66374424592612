import {schema} from 'normalizr'

export const bond = new schema.Entity('bonds')

export const arrayOfBonds = new schema.Array(bond)

export const referral = new schema.Entity('referrals')

export const arrayOfReferrals = new schema.Array(referral)

export const member = new schema.Entity('members')

export const arrayOfMembers = new schema.Array(member)
