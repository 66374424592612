/* eslint-disable no-new */
import React, {useRef, useEffect, FC} from 'react'
import Chart from 'chart.js'
import {ChartProps} from './types'

const DoughnutChart: FC<ChartProps> = ({datasets, labels}) => {
  const ref = useRef<HTMLCanvasElement | null>(null)
  useEffect(() => {
    if (ref.current) {
      new Chart(ref.current, {
        type: 'doughnut',
        data: {
          labels,
          datasets,
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            position: 'left',
            align: 'center',
            labels: {
              fontColor: 'black',
              boxWidth: 20,
              padding: 10,
            },
          },
        },
      })
    }
  }, [datasets, labels])
  return (
    <>
      <canvas
        ref={ref}
        width="400"
        height="400"
        aria-label="Doughnut Chart displaying data"
        role="img"
      >
        <span>Your browser does not support the canvas element.</span>
      </canvas>
      <div className="absolute-center-right text-center">
        <span>
          {datasets?.[0].data.reduce((a, b) => {
            return a + b
          }, 0) || 0}
          <br />
          {datasets?.[0].label}
        </span>
      </div>
    </>
  )
}
export default DoughnutChart
