import {createSelector} from 'reselect'
import {AppState} from '_redux/store.types'
import {ReferralsState} from './referrals.types'

function getReferrals(state: AppState): ReferralsState {
  return state.referrals
}

export const selectReferralsRequesting = createSelector(
  getReferrals,
  referrals => referrals.requesting,
)

export const selectReferralsMeta = createSelector(
  getReferrals,
  referrals => referrals.meta,
)

export const selectReferrals = createSelector(getReferrals, referrals =>
  referrals.allIds.map(id => referrals.byId[id]),
)
