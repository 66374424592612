import {put, call, fork} from 'redux-saga/effects'
import {SagaIterator} from 'redux-saga'
import Cookies from 'js-cookie'
// import log from 'loglevel'
import userAuthService from 'services/api/user/auth.service'
import {toast} from 'react-toastify'
import history from 'helpers/history'
import {
  LoginUserStartAction,
  CheckUserSessionStartAction,
} from './user-auth-actions.types'
import {
  loginUserSuccess,
  loginUserFailure,
  checkUserSessionSuccess,
  checkUserSessionFailure,
} from './user-auth.actions'

export function* checkUserSessionSaga({
  payload: token,
}: CheckUserSessionStartAction): SagaIterator<void> {
  try {
    const {
      data: {message},
    } = yield call([userAuthService, 'checkUserSession'], token)
    yield put(checkUserSessionSuccess(message))
  } catch (error) {
    yield fork(Cookies.remove, 'soarRisingToken')
    yield put(checkUserSessionFailure(null))
  }
}

export function* loginUserSaga({
  payload: {redirectTo, setSubmitting, ...data},
}: LoginUserStartAction): SagaIterator<void> {
  try {
    const {
      data: {token},
    } = yield call([userAuthService, 'loginUser'], data)

    yield put(loginUserSuccess(token))
    toast.success('You are logged in')
    yield fork(Cookies.set, 'soarRisingToken', token, {sameSite: 'none'})
    history.push(redirectTo)
  } catch (error) {
    yield call(setSubmitting, false)
    yield put(loginUserFailure(null))
  }
}
