import {all, call} from 'redux-saga/effects'
import {SagaIterator} from 'redux-saga'
import {userSagas} from './user/user.watchers'
import {membersSagas} from './members/members.watchers'
import {bondsSagas} from './bonds/bonds.watchers'
import {referralsSagas} from './referrals/referrals.watchers'

export default function* rootSaga(): SagaIterator<void> {
  yield all([
    call(userSagas),
    call(bondsSagas),
    call(referralsSagas),
    call(membersSagas),
  ])
}
