import * as Yup from 'yup'
import {parseISO, isAfter} from 'date-fns'

export type TableBodyData = {
  key: string | number
  header: {
    value: string
    underlineValue?: boolean
  }
  data: {
    value: string | number
    underlineValue?: boolean
  }[]
  disabled?: boolean
}

export const EditBondDetailsSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .min(2, 'Too Short!')
    .max(100, 'Too Long!')
    .required('Required'),
  location: Yup.string()
    .min(2, 'Too Short!')
    .max(256, 'Too Long!')
    .required('Required'),
  description: Yup.string()
    .min(2, 'Too Short!')
    .max(1024, 'Too Long!')
    .required('Required'),
})

export const EditBondSeriesSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .min(2, 'Too Short!')
    .max(100, 'Too Long!')
    .required('Required'),
  offer: Yup.number()
    .positive('Must be positive number')
    .integer('Must be whole number')
    .required('Required'),
  tenor: Yup.number()
    .positive('Must be positive number')
    .integer('Must be whole number')
    .required('Required'),
  interest: Yup.number()
    .positive('Must be positive number')
    .required('Required'),
  units: Yup.number()
    .positive('Must be positive number')
    .integer('Must be whole number')
    .required('Required'),
  min_unit: Yup.number()
    .test(
      'test-unit',
      'Min. units must be less than available units',
      function validate(value) {
        // eslint-disable-next-line react/no-this-in-sfc
        return this.parent.units > value
      },
    )
    .positive('Must be positive number')
    .integer('Must be whole number')
    .required('Required'),
  max_unit: Yup.number()
    .test(
      'test-maxUnit',
      'Max. units must be greater than min. units',
      function validate(value) {
        // eslint-disable-next-line react/no-this-in-sfc
        return this.parent.min_unit < value
      },
    )
    .test(
      'test-unit',
      'Max. units must be less than available units',
      function validate(value) {
        // eslint-disable-next-line react/no-this-in-sfc
        return this.parent.units > value
      },
    )
    .positive('Must be positive number')
    .integer('Must be whole number')
    .required('Required'),
  unit_price: Yup.number()
    .positive('Must be positive number')
    .required('Required'),
  interest_paid: Yup.number()
    .positive('Must be positive number')
    .integer('Must be whole number')
    .required('Required'),
  security: Yup.string().trim().required('Required'),
  start_date: Yup.mixed().required('Required'),
  close_date: Yup.mixed()
    .test(
      'test-closeDate',
      'End date must be after start date',
      function validate(value) {
        // eslint-disable-next-line react/no-this-in-sfc
        return isAfter(parseISO(value), parseISO(this.parent.start_date))
      },
    )
    .test(
      'test-maturityDate',
      'Close date must be after maturity date',
      function validate(value) {
        // eslint-disable-next-line react/no-this-in-sfc
        return isAfter(parseISO(value), parseISO(this.parent.maturity_date))
      },
    )
    .required('Required'),
  maturity_date: Yup.mixed()
    .test(
      'test-startDate',
      'Maturity date must be after start date',
      function validate(value) {
        // eslint-disable-next-line react/no-this-in-sfc
        return isAfter(parseISO(value), parseISO(this.parent.start_date))
      },
    )
    .required('Required'),
  estimate: Yup.number()
    .positive('Must be positive number')
    .required('Required'),
  actual: Yup.number().positive('Must be positive number').required('Required'),
  annual: Yup.number().positive('Must be positive number').required('Required'),
  life_of_issue: Yup.number()
    .positive('Must be positive number')
    .required('Required'),
  opportunity_type: Yup.string().required('Required'),
})

export const CreateBondSeriesSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .min(2, 'Too Short!')
    .max(100, 'Too Long!')
    .required('Required'),
  offer: Yup.number()
    .positive('Must be positive number')
    .integer('Must be whole number')
    .required('Required'),
  tenor: Yup.number()
    .positive('Must be positive number')
    .integer('Must be whole number')
    .required('Required'),
  interest: Yup.number()
    .positive('Must be positive number')
    .required('Required'),
  units: Yup.number()
    .positive('Must be positive number')
    .integer('Must be whole number')
    .required('Required'),
  min_unit: Yup.number()
    .test(
      'test-unit',
      'Min. units must be less than available units',
      function validate(value) {
        // eslint-disable-next-line react/no-this-in-sfc
        return this.parent.units > value
      },
    )
    .positive('Must be positive number')
    .integer('Must be whole number')
    .required('Required'),
  max_unit: Yup.number()
    .test(
      'test-maxUnit',
      'Max. units must be greater than min. units',
      function validate(value) {
        // eslint-disable-next-line react/no-this-in-sfc
        return this.parent.min_unit < value
      },
    )
    .test(
      'test-unit',
      'Max. units must be less than available units',
      function validate(value) {
        // eslint-disable-next-line react/no-this-in-sfc
        return this.parent.units > value
      },
    )
    .positive('Must be positive number')
    .integer('Must be whole number')
    .required('Required'),
  unit_price: Yup.number()
    .positive('Must be positive number')
    .required('Required'),
  interest_paid: Yup.number()
    .positive('Must be positive number')
    .integer('Must be whole number')
    .required('Required'),
  security: Yup.string().required('Required'),
  start_date: Yup.mixed().required('Required'),
  close_date: Yup.mixed()
    .test(
      'test-closeDate',
      'End date must be after start date',
      function validate(value) {
        // eslint-disable-next-line react/no-this-in-sfc
        return isAfter(parseISO(value), parseISO(this.parent.start_date))
      },
    )
    .test(
      'test-maturityDate',
      'Close date must be after maturity date',
      function validate(value) {
        // eslint-disable-next-line react/no-this-in-sfc
        return isAfter(parseISO(value), parseISO(this.parent.maturity_date))
      },
    )
    .required('Required'),
  maturity_date: Yup.mixed()
    .test(
      'test-startDate',
      'Maturity date must be after start date',
      function validate(value) {
        // eslint-disable-next-line react/no-this-in-sfc
        return isAfter(parseISO(value), parseISO(this.parent.start_date))
      },
    )
    .required('Required'),
  estimate: Yup.number()
    .positive('Must be positive number')
    .required('Required'),
  actual: Yup.number().positive('Must be positive number').required('Required'),
  annual: Yup.number().positive('Must be positive number').required('Required'),
  life_of_issue: Yup.number()
    .positive('Must be positive number')
    .required('Required'),
  opportunity_type: Yup.string().required('Required'),
})

export type EditBondDetailsFormProps = Yup.InferType<
  typeof EditBondDetailsSchema
>
export type EditBondSeriesFormProps = Yup.InferType<typeof EditBondSeriesSchema>
export type CreateBondSeriesFormProps = Yup.InferType<
  typeof CreateBondSeriesSchema
>
