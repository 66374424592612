import React, {FC, useEffect, useState} from 'react'
import {Row, Col, Card, CardTitle} from 'reactstrap'
import AdminLayout from 'components/layouts/admin.component'
import ActionBar from 'components/common/action-bar.component'
import {theme} from 'AppTheme'
import {useDispatch, useSelector} from 'react-redux'
import {fetchBondsStart} from '_redux/bonds/all/bonds-all.actions'
import {fetchReferralsStart} from '_redux/referrals/referrals.actions'
import {fetchMembersStart} from '_redux/members/members.actions'
import {
  selectBondsRequesting,
  selectBondsMeta,
  selectBonds,
} from '_redux/bonds/bonds.selectors'
import {
  selectReferralsRequesting,
  selectReferralsMeta,
  selectReferrals,
} from '_redux/referrals/referrals.selectors'
import {
  selectMembersRequesting,
  selectMembersMeta,
  selectMembers,
} from '_redux/members/members.selectors'
import DashboardHomeTable from './table.component'
import {TableBodyData} from './types'
import {
  transformBondsForTable,
  transformReferralsForTable,
  transformMembersForTable,
} from './utils'
import DashboardHomeCharts from './charts.component'
import {StyledTableRow} from './styles'

const dummyLabels = ['July 7', 'July 8', 'July 9', 'July 10', 'July 11']
const dummyLabelsTwo = ['Africa', 'Europe', 'Asia', 'Americas', 'Australia']

const dummyData = [
  {
    label: 'Total Investments',
    backgroundColor: theme.colors.primary,
    borderColor: theme.colors.primary,
    data: [2400, 1398, 9800, 9800, 9800],
  },
  {
    label: 'Investment Returns',
    backgroundColor: theme.colors.darkPinkRed,
    borderColor: theme.colors.darkPinkRed,
    data: [4000, 3000, 2000, 2000, 2000],
  },
]
const dummyDataTwo = [
  {
    label: 'Total References',
    backgroundColor: theme.colors.primary,
    borderColor: theme.colors.primary,
    data: [2400, 1398, 9800, 9800, 9800],
  },
  {
    label: 'References Confirmed',
    backgroundColor: theme.colors.darkPinkRed,
    borderColor: theme.colors.darkPinkRed,
    data: [4000, 3000, 2000, 2000, 2000],
  },
]
const dummyDataThree = [
  {
    label: 'Members',
    backgroundColor: [
      theme.colors.primary,
      theme.colors.darkPrimary,
      theme.colors.lightPrimary,
      theme.colors.darkPinkRed,
      theme.colors.pinkRed,
    ],
    borderColor: [
      theme.colors.primary,
      theme.colors.darkPrimary,
      theme.colors.lightPrimary,
      theme.colors.darkPinkRed,
      theme.colors.pinkRed,
    ],
    data: [2400, 1398, 9800, 9800, 9800],
  },
]

const DashboardHome: FC = () => {
  const [currentBondsPage, setCurrentBondsPage] = useState(1)
  const [currentReferralsPage, setCurrentReferralsPage] = useState(1)
  const [currentMembersPage, setCurrentMembersPage] = useState(1)

  const dispatch = useDispatch()

  const bondsRequesting = useSelector(selectBondsRequesting)
  const {pages: bondsPages} = useSelector(selectBondsMeta)
  const bonds = useSelector(selectBonds)

  const referralsRequesting = useSelector(selectReferralsRequesting)
  const {pages: referralsPages} = useSelector(selectReferralsMeta)
  const referrals = useSelector(selectReferrals)

  const membersRequesting = useSelector(selectMembersRequesting)
  const {pages: membersPages} = useSelector(selectMembersMeta)
  const members = useSelector(selectMembers)

  const loadMoreBonds = (): void => {
    setCurrentBondsPage(currentBondsPage + 1)
  }
  const loadMoreReferrals = (): void => {
    setCurrentReferralsPage(currentReferralsPage + 1)
  }
  const loadMoreMembers = (): void => {
    setCurrentMembersPage(currentMembersPage + 1)
  }

  useEffect(() => {
    dispatch(fetchBondsStart(currentBondsPage))
  }, [currentBondsPage, dispatch])
  useEffect(() => {
    dispatch(fetchReferralsStart(currentReferralsPage))
  }, [currentReferralsPage, dispatch])
  useEffect(() => {
    dispatch(fetchMembersStart(currentMembersPage))
  }, [currentMembersPage, dispatch])

  const transformedBonds: TableBodyData[] = transformBondsForTable(bonds)
  const transformedReferrals: TableBodyData[] = transformReferralsForTable(
    referrals,
  )
  const transformedMembers: TableBodyData[] = transformMembersForTable(members)

  return (
    <AdminLayout pageTitle="Dashboard">
      <Row>
        <ActionBar headerText="Dashboard" />
      </Row>
      <Row className="pt-3">
        <DashboardHomeCharts
          investmentChartProps={{labels: dummyLabels, datasets: dummyData}}
          referencesChartProps={{labels: dummyLabels, datasets: dummyDataTwo}}
          membersChartProps={{labels: dummyLabelsTwo, datasets: dummyDataThree}}
        />
      </Row>
      <StyledTableRow className="py-3">
        <Col className="px-0" sm="4">
          <Card body>
            <CardTitle>Investment opportunities</CardTitle>
            <div>
              <DashboardHomeTable
                headers={['Bond', 'Unit price', 'Returns']}
                loading={bondsRequesting}
                page={currentBondsPage}
                body={transformedBonds}
                hasMore={currentBondsPage < bondsPages}
                loadMore={loadMoreBonds}
              />
            </div>
          </Card>
        </Col>
        <Col className="pr-0" sm="4">
          <Card body>
            <CardTitle>Latest referrals</CardTitle>
            <div>
              <DashboardHomeTable
                headers={['Order ID', 'Name', 'Status']}
                loading={referralsRequesting}
                page={currentReferralsPage}
                body={transformedReferrals}
                hasMore={currentReferralsPage < referralsPages}
                loadMore={loadMoreReferrals}
              />
            </div>
          </Card>
        </Col>
        <Col className="pr-0" sm="4">
          <Card body>
            <CardTitle>Latest members</CardTitle>
            <div>
              <DashboardHomeTable
                headers={['', 'Name', 'Join date']}
                loading={membersRequesting}
                page={currentMembersPage}
                body={transformedMembers}
                hasMore={currentMembersPage < membersPages}
                loadMore={loadMoreMembers}
              />
            </div>
          </Card>
        </Col>
      </StyledTableRow>
    </AdminLayout>
  )
}

export default DashboardHome
