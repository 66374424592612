import styled from 'styled-components/macro'
import {Row, Col} from 'reactstrap'

export const StyledChartRow = styled(Row)`
  .card.card-body {
    height: 200px;
    max-width: 200px;
  }
`

export const StyledChartCol = styled(Col)<{color: string}>`
  .rounded-circle {
    border-color: ${(props): string => `${props.theme.colors[props.color]}`};
    border-style: solid;
    border-width: 1rem;
  }
`

export const StyledTableRow = styled(Row)`
  & table {
    max-height: 420px;
    overflow-y: scroll;
  }

  img {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
  }
`
