import {DashboardModelMeta} from 'components/common/types/dashboard-models.types'
import {
  FETCH_REFERRALS_START,
  FETCH_REFERRALS_SUCCESS,
  FETCH_REFERRALS_FAILURE,
  SET_REFERRALS_META,
} from './referrals.constants'
import {NormalizedReferrals, ReferralsActionTypes} from './referrals.types'

export function fetchReferralsStart(page: number): ReferralsActionTypes {
  return {
    type: FETCH_REFERRALS_START,
    payload: page,
  }
}

export function fetchReferralsSuccess(
  data: NormalizedReferrals,
): ReferralsActionTypes {
  return {
    type: FETCH_REFERRALS_SUCCESS,
    payload: data,
  }
}

export function fetchReferralsFailure(): ReferralsActionTypes {
  return {
    type: FETCH_REFERRALS_FAILURE,
  }
}

export function setReferralsMeta(
  meta: DashboardModelMeta,
): ReferralsActionTypes {
  return {
    type: SET_REFERRALS_META,
    payload: meta,
  }
}
