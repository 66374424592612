import React, {FC} from 'react'
import {Col, Card, CardTitle} from 'reactstrap'
import VerticalBarChart from 'components/common/charts/vertical-bar.component'
import {ChartProps} from 'components/common/charts/types'
import DoughnutChart from 'components/common/charts/doughnut.component'

type Props = {
  investmentChartProps: ChartProps
  referencesChartProps: ChartProps
  membersChartProps: ChartProps
}

const DashboardHomeCharts: FC<Props> = ({
  investmentChartProps,
  referencesChartProps,
  membersChartProps,
}) => {
  return (
    <>
      <Col className="px-0" sm="8">
        <Card body>
          <CardTitle>Statistical overview</CardTitle>
          <div className="d-flex">
            <div className="w-50 mb-0">
              <VerticalBarChart {...investmentChartProps} />
            </div>
            <div className="w-50">
              <VerticalBarChart {...referencesChartProps} />
            </div>
          </div>
        </Card>
      </Col>
      <Col className="pr-0" sm="4">
        <Card body className="h-100">
          <CardTitle>Member locations</CardTitle>
          <div className="position-relative">
            <DoughnutChart {...membersChartProps} />
          </div>
        </Card>
      </Col>
    </>
  )
}

export default DashboardHomeCharts
