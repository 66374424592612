import {SagaIterator} from 'redux-saga'
import {takeLatest, all, call} from 'redux-saga/effects'
import {LOGIN_USER_START, CHECK_USER_SESSION_START} from './user.constants'
import {loginUserSaga, checkUserSessionSaga} from './auth/user-auth.sagas'

export function* onCheckUserSessionStart(): SagaIterator<void> {
  yield takeLatest(CHECK_USER_SESSION_START, checkUserSessionSaga)
}

export function* onLoginUserStart(): SagaIterator<void> {
  yield takeLatest(LOGIN_USER_START, loginUserSaga)
}

export function* userSagas(): SagaIterator<void> {
  yield all([call(onCheckUserSessionStart), call(onLoginUserStart)])
}
