import React, {FC, useEffect, Suspense} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Router} from 'react-router-dom'
import {ToastContainer} from 'react-toastify'
import Observer from 'fontfaceobserver'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {loadProgressBar} from 'axios-progress-bar'
import AppTheme from 'AppTheme'
import {AppContainer, GlobalStyle} from 'app.styles'
import SplashScreen from 'components/common/splash-screen.component'
import {selectUserSessionIsRequesting} from '_redux/user/user.selectors'
import {checkUserSessionStart} from '_redux/user/auth/user-auth.actions'
import {getCookieValue} from 'helpers/utils'
import history from 'helpers/history'
import AllRoutes from 'Routes'
import 'axios-progress-bar/dist/nprogress.css'
import 'react-toastify/dist/ReactToastify.min.css'

const App: FC = () => {
  const userToken = getCookieValue('soarRisingToken')
  const dispatch = useDispatch()
  const sessionRequesting = useSelector(selectUserSessionIsRequesting)
  useEffect(() => {
    const fontfamily = new Observer('Nunito Sans')
    fontfamily.load().then(() => {
      document.documentElement.classList.add('fontfamily-loaded')
    })
  })
  useEffect(() => {
    if (userToken && sessionRequesting) {
      dispatch(checkUserSessionStart(userToken))
    }
  }, [sessionRequesting, userToken, dispatch])
  return (
    <AppTheme>
      <GlobalStyle />
      <AppContainer className="vw-100 vh-100">
        <Router history={history}>
          {loadProgressBar()}
          {userToken && sessionRequesting ? (
            <SplashScreen />
          ) : (
            <Suspense fallback={<SplashScreen />}>
              <ToastContainer />
              <AllRoutes />
            </Suspense>
          )}
        </Router>
      </AppContainer>
    </AppTheme>
  )
}

export default App
