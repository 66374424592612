import {combineReducers} from 'redux'
import {DashboardModelMeta} from 'components/common/types/dashboard-models.types'
import {
  FETCH_MEMBERS_START,
  FETCH_MEMBERS_SUCCESS,
  FETCH_MEMBERS_FAILURE,
  SET_MEMBERS_META,
} from './members.constants'
import {
  MembersDictionary,
  MembersIdList,
  MembersRequestingStateActions,
  MembersStateActions,
} from './members.types'
import {SetMembersMetaAction} from './members-actions.types'

function membersById(
  state: MembersDictionary = {},
  {type, payload}: MembersStateActions,
): MembersDictionary {
  switch (type) {
    case FETCH_MEMBERS_START: {
      if (typeof payload === 'string' && payload === 1) {
        return {}
      }
      return state
    }
    case FETCH_MEMBERS_SUCCESS: {
      if (typeof payload !== 'number') {
        return {...state, ...payload.entities.members}
      }
      return state
    }
    default:
      return state
  }
}

function allMembersIds(
  state: MembersIdList = [],
  {type, payload}: MembersStateActions,
): MembersIdList {
  switch (type) {
    case FETCH_MEMBERS_START: {
      if (typeof payload === 'string' && payload === 1) {
        return []
      }
      return state
    }
    case FETCH_MEMBERS_SUCCESS: {
      if (typeof payload !== 'number') {
        const stateCopy = [...state, ...payload.result]
        return Array.from(new Set(stateCopy))
      }
      return state
    }
    default:
      return state
  }
}

function membersMeta(
  state: DashboardModelMeta = {pages: 0},
  {type, payload}: SetMembersMetaAction,
): DashboardModelMeta {
  switch (type) {
    case SET_MEMBERS_META:
      return payload
    default:
      return state
  }
}

function membersRequesting(
  state = true,
  {type}: MembersRequestingStateActions,
): boolean {
  switch (type) {
    case FETCH_MEMBERS_START:
      return true
    case FETCH_MEMBERS_SUCCESS:
    case FETCH_MEMBERS_FAILURE:
      return false
    default:
      return state
  }
}

const membersReducer = combineReducers({
  byId: membersById,
  allIds: allMembersIds,
  meta: membersMeta,
  requesting: membersRequesting,
})

export default membersReducer
