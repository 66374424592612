import {put, call} from 'redux-saga/effects'
import {SagaIterator} from 'redux-saga'
import * as schema from '_redux/schema'
import log from 'loglevel'
import BondsService from 'services/api/bonds/service'
import {normalize} from 'normalizr'
import {toast} from 'react-toastify'
import {fetchBondsStart, fetchBondsSummaryStart} from '../all/bonds-all.actions'
import {
  AddBondStartAction,
  FetchBondStartAction,
  EditBondDetailsStartAction,
  EditBondSeriesStartAction,
  CreateBondSeriesStartAction,
  FetchBondSeriesInvestorsStartAction,
  SetBondSeriesStatusStartAction,
} from './bonds-single-actions.types'
import {
  fetchBondSuccess,
  fetchBondFailure,
  fetchBondStart,
  fetchBondSeriesInvestorsFailure,
  fetchBondSeriesInvestorsSuccess,
} from './bonds-single.actions'

export function* AddBondStartSaga({
  payload: {setSubmitting, toggleModal, ...data},
}: AddBondStartAction): SagaIterator<void> {
  const bondsService = new BondsService()
  try {
    yield call([bondsService, 'addBond'], data)
    yield put(fetchBondsStart(1))
    yield put(fetchBondsSummaryStart())
    yield call(setSubmitting, false)
    yield call(toggleModal)
    yield call(toast.success, 'Bond added successfully')
  } catch (error) {
    log.warn(error)
    yield call(setSubmitting, false)
  }
}

export function* FetchBondStartSaga({
  payload: id,
}: FetchBondStartAction): SagaIterator<void> {
  const bondsService = new BondsService()
  try {
    const {
      data: {data: bond},
    } = yield call([bondsService, 'fetchBond'], id)
    const normalizedBonds = yield call(normalize, bond, schema.arrayOfBonds)
    yield put(fetchBondSuccess(normalizedBonds))
  } catch (error) {
    log.warn(error)
    yield put(fetchBondFailure())
  }
}

export function* EditBondDetailsStartSaga({
  payload: {setSubmitting, toggleModal, ...data},
}: EditBondDetailsStartAction): SagaIterator<void> {
  const bondsService = new BondsService()
  try {
    yield call([bondsService, 'editBondDetails'], data)
    yield put(fetchBondStart(`${data.bond_id}`))
    if (setSubmitting) yield call(setSubmitting, false)
    if (toggleModal) yield call(toggleModal)
    yield call(toast.success, 'Bond details updated successfully')
  } catch (error) {
    log.warn(error)
    if (setSubmitting) yield call(setSubmitting, false)
  }
}

export function* EditBondSeriesStartSaga({
  payload: {setSubmitting, toggleModal, ...data},
}: EditBondSeriesStartAction): SagaIterator<void> {
  const bondsService = new BondsService()
  try {
    yield call([bondsService, 'editBondSeries'], data)
    yield put(fetchBondStart(`${data.bond_id}`))
    yield call(setSubmitting, false)
    yield call(toggleModal)
    yield call(toast.success, 'Bond series updated successfully')
  } catch (error) {
    log.warn(error)
    yield call(setSubmitting, false)
  }
}

export function* CreateBondSeriesStartSaga({
  payload: {setSubmitting, toggleModal, ...data},
}: CreateBondSeriesStartAction): SagaIterator<void> {
  const bondsService = new BondsService()
  try {
    yield call([bondsService, 'createBondSeries'], data)
    yield put(fetchBondStart(`${data.bond_id}`))
    yield call(setSubmitting, false)
    yield call(toggleModal)
    yield call(toast.success, 'Bond series created successfully')
  } catch (error) {
    log.warn(error)
    yield call(setSubmitting, false)
  }
}

export function* FetchBondSeriesInvestorsStartSaga({
  payload: id,
}: FetchBondSeriesInvestorsStartAction): SagaIterator<void> {
  const bondsService = new BondsService()
  try {
    const {
      data: {data: investors},
    } = yield call([bondsService, 'fetchBondSeriesInvestors'], id)
    yield put(fetchBondSeriesInvestorsSuccess({seriesId: `${id}`, investors}))
  } catch (error) {
    log.warn(error)
    yield put(fetchBondSeriesInvestorsFailure())
  }
}

export function* SetBondSeriesStatusStartSaga({
  payload: {seriesId, bondId},
}: SetBondSeriesStatusStartAction): SagaIterator<void> {
  const bondsService = new BondsService()
  try {
    yield call([bondsService, 'setBondSeriesStatus'], seriesId)
    yield put(fetchBondStart(bondId))
    yield call(toast.success, 'Bond series activated successfully')
  } catch (error) {
    log.warn(error)
    yield put(fetchBondSeriesInvestorsFailure())
  }
}
