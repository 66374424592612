import React, {FC} from 'react'
import {Table, Button} from 'reactstrap'
import LoadingSpinner from 'components/common/loading-spinner.component'
import history from 'helpers/history'
import {TableBodyData} from './types'

type Props = {
  headers: string[]
  loading: boolean
  page: number
  body: TableBodyData[]
  hasMore: boolean
  loadMore(): void
}

const navigate = (url: string | undefined) => {
  if (url) history.push(url)
}

const DashboardBondsDetailsTable: FC<Props> = ({
  headers,
  body,
  loading,
  page,
  hasMore,
  loadMore,
}) => {
  return (
    <>
      <Table responsive hover>
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th key={header + index}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {body.map(data => {
            const tableHeader = (
              <td
                onClick={
                  data.header.isLink && data.header.url
                    ? () => {
                        navigate(data.header.url)
                      }
                    : undefined
                }
                className={`${data.header.isLink ? 'clickable' : ''}`}
              >
                {data.header.isImage ? (
                  <img src={data.header.value} alt="Avatar" />
                ) : data.header.underlineValue ? (
                  <u>{data.header.value}</u>
                ) : (
                  data.header.value
                )}
              </td>
            )
            const tableData = data.data.map(
              ({value, underlineValue, isImage, isLink, url}, index) => (
                <td
                  onClick={
                    isLink && url
                      ? () => {
                          navigate(url)
                        }
                      : undefined
                  }
                  key={index}
                  className={`${isLink ? 'clickable' : ''}`}
                >
                  {isImage && typeof value === 'string' ? (
                    <img src={value} alt="Avatar" />
                  ) : underlineValue ? (
                    <u>{value}</u>
                  ) : (
                    value
                  )}
                </td>
              ),
            )
            return (
              <tr className={data.disabled ? 'text-muted' : ''} key={data.key}>
                {tableHeader}
                {tableData}
              </tr>
            )
          })}
          {(page === 1 && body.length) || !loading ? null : (
            <tr>
              <td colSpan={100} className="text-center pt-4">
                <LoadingSpinner />
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {!loading && hasMore ? (
        <Button className="mb-1" onClick={loadMore}>
          More
        </Button>
      ) : null}
    </>
  )
}

export default DashboardBondsDetailsTable
