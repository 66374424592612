import {
  CHECK_USER_SESSION_START,
  CHECK_USER_SESSION_SUCCESS,
  CHECK_USER_SESSION_FAILURE,
  LOGIN_USER_START,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
} from '../user.constants'
import {UserActionTypes} from '../user.types'
import {LoginCredentials} from './user-auth.types'

export function checkUserSessionStart(token: string): UserActionTypes {
  return {
    type: CHECK_USER_SESSION_START,
    payload: token,
  }
}

export function checkUserSessionSuccess(
  sessionValid: boolean,
): UserActionTypes {
  return {
    type: CHECK_USER_SESSION_SUCCESS,
    payload: sessionValid,
  }
}

export function checkUserSessionFailure(sessionValid: null): UserActionTypes {
  return {
    type: CHECK_USER_SESSION_FAILURE,
    payload: sessionValid,
  }
}

export function loginUserStart(loginData: LoginCredentials): UserActionTypes {
  return {
    type: LOGIN_USER_START,
    payload: loginData,
  }
}

export function loginUserSuccess(token: string): UserActionTypes {
  return {
    type: LOGIN_USER_SUCCESS,
    payload: token,
  }
}

export function loginUserFailure(token: null): UserActionTypes {
  return {
    type: LOGIN_USER_FAILURE,
    payload: token,
  }
}
