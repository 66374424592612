import React, {FC} from 'react'
import {Card} from 'reactstrap'
import {BondsSummary} from '_redux/bonds/all/bonds-all.types'
import {FaAngleUp, FaAngleDown} from 'react-icons/fa'
import LoadingSpinner from 'components/common/loading-spinner.component'
import {formatCash} from 'helpers/utils'
import {StyledChartCol} from './styles'

type Props = {
  bondsSummary: BondsSummary | null
  loading: boolean
}

const loaderOrNoResult = (loading: boolean): JSX.Element => (
  <div className="mx-auto my-auto">
    {loading ? (
      <LoadingSpinner type="Rings" width={120} height={120} />
    ) : (
      <span>No Result</span>
    )}
  </div>
)

const DashboardBondMgmtCharts: FC<Props> = ({bondsSummary, loading}) => {
  return (
    <>
      <StyledChartCol color="darkPrimary" className="px-0" sm="2">
        <Card body className="p-0 border-0">
          {bondsSummary ? (
            <div className="rounded-circle w-100 h-100">
              <div className="absolute-center text-center">
                <span className="font-weight-bold h2">
                  {bondsSummary.bond_holders}
                </span>
                <br />
                <span className="font-weight-light">bond holders</span>
              </div>
            </div>
          ) : (
            loaderOrNoResult(loading)
          )}
        </Card>
        {bondsSummary ? (
          <>
            <p className="text-center font-weight-bold mb-0 pt-3">
              increased by
            </p>
            <p className="text-center text-muted">
              <FaAngleUp /> up {bondsSummary.increased_bond_holder}%
            </p>
          </>
        ) : null}
      </StyledChartCol>
      <StyledChartCol color="primary" className="px-0" sm="2">
        <Card body className="p-0 border-0">
          {bondsSummary ? (
            <div className="rounded-circle w-100 h-100">
              <div className="absolute-center text-center">
                <span className="font-weight-bold h2">
                  {bondsSummary.units_sold}
                </span>
                <br />
                <span className="font-weight-light">unit sold</span>
              </div>
            </div>
          ) : (
            loaderOrNoResult(loading)
          )}
        </Card>
        {bondsSummary ? (
          <>
            <p className="text-center font-weight-bold mb-0 pt-3">
              increased by
            </p>
            <p className="text-center text-muted">
              <FaAngleUp /> up {bondsSummary.increased_unit_sold}%
            </p>
          </>
        ) : null}
      </StyledChartCol>
      <StyledChartCol color="lightPrimary" className="px-0" sm="2">
        <Card body className="p-0 border-0">
          {bondsSummary ? (
            <div className="rounded-circle w-100 h-100">
              <div className="absolute-center text-center">
                <span className="font-weight-bold h2">
                  £{formatCash(+bondsSummary.interest)}
                </span>
                <br />
                <span className="font-weight-light">interest</span>
              </div>
            </div>
          ) : (
            loaderOrNoResult(loading)
          )}
        </Card>
        {bondsSummary ? (
          <>
            <p className="text-center font-weight-bold mb-0 pt-3">growth</p>
            <p className="text-center text-muted">
              <FaAngleDown /> down 0.3%
            </p>
          </>
        ) : null}
      </StyledChartCol>
      <StyledChartCol color="darkPinkRed" className="px-0" sm="2">
        <Card body className="p-0 border-0">
          {bondsSummary ? (
            <div className="rounded-circle w-100 h-100">
              <div className="absolute-center text-center">
                <span className="font-weight-bold h2">
                  £{formatCash(+bondsSummary.total_incl_interest)}
                </span>
                <br />
                <p className="font-weight-light mb-0">total</p>
                <p className="font-weight-light mb-0">(incl. interest)</p>
              </div>
            </div>
          ) : (
            loaderOrNoResult(loading)
          )}
        </Card>
        {bondsSummary ? (
          <>
            <p className="text-center font-weight-bold mb-0 pt-3">growth</p>
            <p className="text-center text-muted">
              <FaAngleUp /> up {bondsSummary.increased_total_incl}%
            </p>
          </>
        ) : null}
      </StyledChartCol>
      <StyledChartCol color="pinkRed" className="px-0" sm="2">
        <Card body className="p-0 border-0">
          {bondsSummary ? (
            <div className="rounded-circle w-100 h-100">
              <div className="absolute-center text-center">
                <span className="font-weight-bold h2">
                  {bondsSummary.bonds}
                </span>
                <br />
                <span className="font-weight-light">bonds</span>
              </div>
            </div>
          ) : (
            loaderOrNoResult(loading)
          )}
        </Card>
        {bondsSummary ? (
          <>
            <p className="text-center font-weight-bold mb-0 pt-3">
              increased by
            </p>
            <p className="text-center text-muted">
              <FaAngleUp /> up {bondsSummary.increaded_bond}%
            </p>
          </>
        ) : null}
      </StyledChartCol>
    </>
  )
}

export default DashboardBondMgmtCharts
