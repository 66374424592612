import styled from 'styled-components/macro'
import {Row} from 'reactstrap'

export const StyledTableRow = styled(Row)`
  & table {
    font-size: 12px;
  }

  .card.card-body {
    height: 420px;
    overflow-y: scroll;
  }

  img {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
  }
`
