import {combineReducers} from 'redux'
import {DashboardModelMeta} from 'components/common/types/dashboard-models.types'
import {
  FETCH_BONDS_START,
  FETCH_BONDS_SUCCESS,
  FETCH_BONDS_FAILURE,
  SET_BONDS_META,
  FETCH_BONDS_SUMMARY_START,
  FETCH_BONDS_SUMMARY_SUCCESS,
  FETCH_BONDS_SUMMARY_FAILURE,
  FETCH_BOND_SUCCESS,
  FETCH_BOND_START,
  FETCH_BOND_FAILURE,
  FETCH_BOND_SERIES_INVESTORS_SUCCESS,
  CLEAR_BOND_SERIES_INVESTORS,
  FETCH_BOND_SERIES_INVESTORS_START,
  FETCH_BOND_SERIES_INVESTORS_FAILURE,
} from './bonds.constants'
import {
  BondsRequestingStateActions,
  BondsStateActions,
  BondsSummaryStateActions,
  BondsSummaryRequestingStateActions,
  BondSeriesInvestorsStateActions,
  BondSeriesInvestorsRequestingStateActions,
} from './bonds.types'
import {BondsDictionary, BondsIdList, BondsSummary} from './all/bonds-all.types'
import {SetBondsMetaAction} from './all/bonds-all-actions.types'
import {SeriesInvestors} from './single/bonds-single.types'

function bondsById(
  state: BondsDictionary = {},
  {type, payload}: BondsStateActions,
): BondsDictionary {
  switch (type) {
    case FETCH_BONDS_START: {
      if (typeof payload === 'string' && payload === 1) {
        return {}
      }
      return state
    }
    case FETCH_BOND_SUCCESS:
    case FETCH_BONDS_SUCCESS: {
      if (typeof payload !== 'number') {
        return {...state, ...payload.entities.bonds}
      }
      return state
    }
    default:
      return state
  }
}

function allBondsIds(
  state: BondsIdList = [],
  {type, payload}: BondsStateActions,
): BondsIdList {
  switch (type) {
    case FETCH_BONDS_START: {
      if (typeof payload === 'string' && payload === '1') {
        return []
      }
      return state
    }
    case FETCH_BOND_SUCCESS:
    case FETCH_BONDS_SUCCESS: {
      if (typeof payload !== 'number') {
        const stateCopy = [...state, ...payload.result]
        return Array.from(new Set(stateCopy))
      }
      return state
    }
    default:
      return state
  }
}

function bondsMeta(
  state: DashboardModelMeta = {pages: 0, total: 0},
  {type, payload}: SetBondsMetaAction,
): DashboardModelMeta {
  switch (type) {
    case SET_BONDS_META:
      return payload
    default:
      return state
  }
}

function bondsRequesting(
  state = true,
  {type}: BondsRequestingStateActions,
): boolean {
  switch (type) {
    case FETCH_BOND_START:
    case FETCH_BONDS_START:
      return true
    case FETCH_BOND_SUCCESS:
    case FETCH_BOND_FAILURE:
    case FETCH_BONDS_SUCCESS:
    case FETCH_BONDS_FAILURE:
      return false
    default:
      return state
  }
}

function bondsSummary(
  state: BondsSummary | null = null,
  action: BondsSummaryStateActions,
): BondsSummary | null {
  switch (action.type) {
    case FETCH_BONDS_SUMMARY_START:
      return null
    case FETCH_BONDS_SUMMARY_SUCCESS: {
      if (action.payload) return action.payload
      return state
    }
    default:
      return state
  }
}

function bondsSummaryRequesting(
  state = true,
  {type}: BondsSummaryRequestingStateActions,
): boolean {
  switch (type) {
    case FETCH_BONDS_SUMMARY_START:
      return true
    case FETCH_BONDS_SUMMARY_SUCCESS:
    case FETCH_BONDS_SUMMARY_FAILURE:
      return false
    default:
      return state
  }
}

function bondSeriesInvestors(
  state: SeriesInvestors = {},
  action: BondSeriesInvestorsStateActions,
): SeriesInvestors {
  switch (action.type) {
    case FETCH_BOND_SERIES_INVESTORS_SUCCESS: {
      if (action.payload) {
        return {
          ...state,
          [action.payload.seriesId]: action.payload.investors,
        }
      }
      return state
    }
    case CLEAR_BOND_SERIES_INVESTORS:
      return {}
    default:
      return state
  }
}

function bondSeriesInvestorsRequesting(
  state = true,
  {type}: BondSeriesInvestorsRequestingStateActions,
): boolean {
  switch (type) {
    case FETCH_BOND_SERIES_INVESTORS_START:
      return true
    case FETCH_BOND_SERIES_INVESTORS_SUCCESS:
    case FETCH_BOND_SERIES_INVESTORS_FAILURE:
    case CLEAR_BOND_SERIES_INVESTORS:
      return false
    default:
      return state
  }
}

const bondsReducer = combineReducers({
  byId: bondsById,
  allIds: allBondsIds,
  meta: bondsMeta,
  requesting: bondsRequesting,
  summary: bondsSummary,
  summaryRequesting: bondsSummaryRequesting,
  investors: bondSeriesInvestors,
  investorsRequesting: bondSeriesInvestorsRequesting,
})

export default bondsReducer
