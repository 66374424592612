import styled from 'styled-components/macro'
import {Row} from 'reactstrap'

export const StyledNavLinkRow = styled(Row)`
  .nav-link {
    color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    &:hover {
      color: #000;
    }

    &.active {
      color: ${(props): string => `${props.theme.colors.primary}`};
      font-weight: bold;
    }
  }
`
