import React, {FC} from 'react'
import {Table} from 'reactstrap'
import LoadingSpinner from 'components/common/loading-spinner.component'
import {TableBodyData} from './types'

type Props = {
  headers: string[]
  loading: boolean
  body: TableBodyData[]
}

const InvestorsTable: FC<Props> = ({headers, body, loading}) => {
  return (
    <Table responsive hover>
      <thead>
        <tr>
          {headers.map(header => (
            <th key={header}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {body.map(data => {
          const tableHeader = (
            <td>
              {data.header.underlineValue ? (
                <u>{data.header.value}</u>
              ) : (
                data.header.value
              )}
            </td>
          )
          const tableData = data.data.map(({value, underlineValue}, index) => (
            <td key={index}>{underlineValue ? <u>{value}</u> : value}</td>
          ))
          return (
            <tr className={data.disabled ? 'text-muted' : ''} key={data.key}>
              {tableHeader}
              {tableData}
            </tr>
          )
        })}
        {body.length || !loading ? null : (
          <tr>
            <td colSpan={100} className="text-center pt-4">
              <LoadingSpinner />
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  )
}

export default InvestorsTable
