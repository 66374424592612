import {put, call} from 'redux-saga/effects'
import {SagaIterator} from 'redux-saga'
import * as schema from '_redux/schema'
import log from 'loglevel'
import MembersService from 'services/api/members/service'
import {normalize} from 'normalizr'
import {FetchMembersStartAction} from './members-actions.types'
import {
  fetchMembersFailure,
  fetchMembersSuccess,
  setMembersMeta,
} from './members.actions'

export function* FetchMembersStartSaga({
  payload: currPage,
}: FetchMembersStartAction): SagaIterator<void> {
  const membersService = new MembersService()
  try {
    const {
      data: {data: members, pages},
    } = yield call([membersService, 'fetchMembers'], currPage)
    const normalizedMembers = yield call(
      normalize,
      members,
      schema.arrayOfMembers,
    )
    yield put(fetchMembersSuccess(normalizedMembers))
    yield put(setMembersMeta({pages}))
  } catch (error) {
    log.warn(error)
    yield put(fetchMembersFailure())
  }
}
