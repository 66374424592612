import React from 'react'
import {RouteComponentProps, Switch, Route} from 'react-router-dom'
import DashboardOverview from 'components/dashboard/home/overview.component'
import DashboardBondMgmtOverview from 'components/dashboard/bond-mgmt/overview.component'
import DashboardBondProfileOverview from 'components/dashboard/bond-profile/overview.component'
import DashboardSettingsAccessOverview from 'components/dashboard/settings-access/overview.component'

const DashboardPages: React.FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Route exact path="/dashboard" component={DashboardOverview} />
      <Route
        exact
        path="/dashboard/bond-mgmt"
        component={DashboardBondMgmtOverview}
      />
      <Route
        exact
        path="/dashboard/bond-profile/:id"
        component={DashboardBondProfileOverview}
      />
      <Route
        exact
        path="/dashboard/settings-access"
        component={DashboardSettingsAccessOverview}
      />
    </Switch>
  )
}
export default DashboardPages
