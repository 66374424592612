import React, {FC} from 'react'
import {Row} from 'reactstrap'
import {Helmet} from 'react-helmet'
import Header from 'components/common/header/overview.component'
import {StyledAdminLayoutContainer} from './admin.styles'

type Props = {
  pageTitle: string
}

const AdminLayout: FC<Props> = ({pageTitle, children}) => {
  return (
    <>
      <Helmet>
        <title>{pageTitle} | SoarRising</title>
      </Helmet>
      <StyledAdminLayoutContainer className="d-flex flex-column h-100">
        <Row>
          <Header />
        </Row>
        {children}
      </StyledAdminLayoutContainer>
    </>
  )
}

export default AdminLayout
