import {Investor} from '_redux/bonds/single/bonds-single.types'
import {TableBodyData} from './types'

export const transformInvestorsForTable = (
  investors: Investor[],
): TableBodyData[] => {
  return investors.map(investor => ({
    key: investor?.id ?? '',
    header: {
      value: investor?.id.toString() ?? '',
    },
    data: [
      {value: investor?.Name ?? '-', underlineValue: true},
      {value: investor?.Membership ?? '-'},
      {value: investor?.Status ?? '-'},
      {value: investor?.Referrals ?? '-'},
      {value: investor?.Unit ?? '-'},
      {value: `£${investor?.Amount.toLocaleString()}` ?? '-'},
    ],
  }))
}
