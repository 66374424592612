import {Bond} from '_redux/bonds/single/bonds-single.types'
import {PlaceholderImage} from 'helpers/placeholder-image'
import format from 'date-fns/format'
import {TableBodyData} from './types'

export const transformBondsForTable = (
  bonds: {bond: Bond; bondSeriesKeys: string[]}[],
): TableBodyData[] => {
  return bonds.flatMap(({bond, bondSeriesKeys}) => {
    if (!bondSeriesKeys.length) {
      return [
        {
          key: `${bond?.id}` ?? '',
          header: {
            value: '',
          },
          data: [
            {
              value: bond.image || PlaceholderImage,
              isImage: true,
            },
            {
              value: `${bond?.bond_name}` ?? '',
              underlineValue: true,
              isLink: true,
              url: `/dashboard/bond-profile/${bond.id}`,
            },
            {
              value: bond.location ?? '-',
            },
            {
              value: '-',
            },
            {
              value: '-',
            },
            {
              value: '-',
            },
            {
              value: '-',
            },
            {
              value: '-',
            },
            {
              value: '-',
            },
            {
              value: '-',
            },
          ],
        },
      ]
    }
    return bondSeriesKeys.map(seriesKey => ({
      key: `${bond?.id}${seriesKey}` ?? '',
      header: {
        value: '',
      },
      data: [
        {
          value: bond.image || PlaceholderImage,
          isImage: true,
        },
        {
          value: `${bond?.bond_name} - (${seriesKey})` ?? '',
          underlineValue: true,
          isLink: true,
          url: `/dashboard/bond-profile/${bond.id}`,
        },
        {
          value: bond.location ?? '-',
        },
        {
          value: bond?.series?.[seriesKey].status ? 'Active' : 'Inactive',
        },
        {
          value: format(
            new Date(bond?.series?.[seriesKey].start_date),
            'd MMM yyyy',
          ),
        },
        {
          value: format(
            new Date(bond?.series?.[seriesKey].close_date),
            'd MMM yyyy',
          ),
        },
        {
          value: bond?.series?.[seriesKey].members ?? '-',
        },
        {
          value: `£${
            bond?.series?.[seriesKey]?.unit_price.toLocaleString() ?? '-'
          }`,
        },
        {
          value: bond?.series?.[seriesKey].units ?? '-',
        },
        {
          value: `£${
            bond?.series?.[seriesKey].returns.toLocaleString() ?? '-'
          }`,
        },
      ],
    }))
  })
}
