import React, {FC} from 'react'
import {Button} from 'reactstrap'
import {IconContext} from 'react-icons'
import {/*FaDownload*/ FaUpload, FaPlusCircle} from 'react-icons/fa'
import {StyledActionBar} from './action-bar.styles'

type Props = {
  headerText: string
  onAddClick?(): void
}

const actionBarIconValues = {
  color: '#D50F10',
  size: '1.2em',
  style: {verticalAlign: 'middle'},
}

const ActionBar: FC<Props> = ({headerText, onAddClick}) => {
  return (
    <StyledActionBar className="d-flex justify-content-between align-items-center w-100 border-bottom pt-3">
      <h2 className="font-weight-bold">{headerText}</h2>
      <IconContext.Provider value={actionBarIconValues}>
        <div>
          <Button color="link pr-4">
            Export data &nbsp;
            <FaUpload />
          </Button>
          <Button onClick={onAddClick} color="link pr-0">
            Add item &nbsp;
            <FaPlusCircle />
          </Button>
        </div>
      </IconContext.Provider>
    </StyledActionBar>
  )
}

export default ActionBar
