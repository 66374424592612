import {SagaIterator} from 'redux-saga'
import {takeLatest, all, call} from 'redux-saga/effects'
import {FETCH_MEMBERS_START} from './members.constants'
import {FetchMembersStartSaga} from './members.sagas'

export function* onFetchMembersStart(): SagaIterator<void> {
  yield takeLatest(FETCH_MEMBERS_START, FetchMembersStartSaga)
}

export function* membersSagas(): SagaIterator<void> {
  yield all([call(onFetchMembersStart)])
}
