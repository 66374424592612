export const ADMIN_AUTH_SESSION_ENDPOINT = '/api/ping-admin'
export const ADMIN_LOGIN_ENDPOINT = '/api-aut-admin-login/'
export const FETCH_BONDS_ENDPOINT = '/api/view-bond'
export const FETCH_BONDS_SUMMARY_ENDPOINT = '/api/admin-overview'
export const FETCH_BOND_ENDPOINT = '/api/single-bond'
export const ADD_BOND_ENDPOINT = '/api/add-bond'
export const EDIT_BOND_ENDPOINT = '/api/edit-bond'
export const ADD_SERIES_ENDPOINT = '/api/add-series'
export const EDIT_SERIES_ENDPOINT = '/api/edit-series'
export const EDIT_SERIES_STATUS_ENDPOINT = '/api/activate-bond'
export const FETCH_SERIES_INVESTORS_ENDPOINT = '/api/admin/view-investors'
export const FETCH_REFERRALS_ENDPOINT = '/api/latest-referral'
export const FETCH_MEMBERS_ENDPOINT = '/api/latest-members'
