import {AxiosResponse} from 'axios'
import {getCookieValue} from 'helpers/utils'
import requestHandler from '../axios-config'
import {HttpMethods} from '../axios.types'
import {FETCH_REFERRALS_ENDPOINT} from '../endpoints'

class ReferralsService {
  requestHandler: typeof requestHandler
  token: string
  constructor() {
    this.requestHandler = requestHandler
    this.token = getCookieValue('soarRisingToken') || ''
  }

  async fetchReferrals(page: number): Promise<AxiosResponse<any>> {
    const res = await this.requestHandler({
      method: HttpMethods.GET,
      url: `${FETCH_REFERRALS_ENDPOINT}?page=${page}`,
      data: {
        headers: {
          Authorization: `Token ${this.token}`,
        },
      },
    })
    return res
  }
}

export default ReferralsService
