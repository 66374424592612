import {combineReducers} from 'redux'
import {
  CHECK_USER_SESSION_START,
  CHECK_USER_SESSION_SUCCESS,
  CHECK_USER_SESSION_FAILURE,
  LOGIN_USER_SUCCESS,
} from './user.constants'
import {
  UserSessionRequestingStateActions,
  UserAuthStateActions,
} from './user.types'

function userAuth(state = false, {type}: UserAuthStateActions): boolean {
  switch (type) {
    case CHECK_USER_SESSION_SUCCESS:
    case LOGIN_USER_SUCCESS:
      return true
    case CHECK_USER_SESSION_FAILURE:
      return false
    default:
      return state
  }
}

function userSessionRequesting(
  state = true,
  {type}: UserSessionRequestingStateActions,
): boolean {
  switch (type) {
    case CHECK_USER_SESSION_START:
      return true
    case CHECK_USER_SESSION_SUCCESS:
    case CHECK_USER_SESSION_FAILURE:
    case LOGIN_USER_SUCCESS:
      return false
    default:
      return state
  }
}

const userReducer = combineReducers({
  auth: userAuth,
  sessionRequesting: userSessionRequesting,
})

export default userReducer
