import React, {FC} from 'react'
import {Table} from 'reactstrap'

const AccessLevelTable: FC = () => {
  return (
    <Table responsive hover>
      <thead>
        <tr>
          <th>Name</th>
          <th>Status</th>
          <th>Email</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <u>Nigel Ballard</u>
          </td>
          <td>Admin+</td>
          <td>n.ballard@gmail.com</td>
        </tr>
        <tr>
          <td>
            <u>Natalie Mills</u>
          </td>
          <td>Admin</td>
          <td>nat.mills@gmail.com</td>
        </tr>
        <tr>
          <td>
            <u>Jeff Humphries</u>
          </td>
          <td>Admin</td>
          <td>jhumphries@aol.com</td>
        </tr>
        <tr>
          <td>
            <u>Jane Doe</u>
          </td>
          <td>Tech Support</td>
          <td>jd009@gmail.com</td>
        </tr>
        <tr>
          <td>
            <u>John Smith</u>
          </td>
          <td>Tech Support</td>
          <td>j.smith87@gmail.com</td>
        </tr>
      </tbody>
    </Table>
  )
}

export default AccessLevelTable
