import React, {FC} from 'react'
import {FormikProps, FormikHelpers, Formik} from 'formik'
import {Input, Submit} from 'formstrap'
import {FormGroup, Col, Label} from 'reactstrap'
import {addBondStart} from '_redux/bonds/single/bonds-single.actions'
import LoadingSpinner from 'components/common/loading-spinner.component'
import {useDispatch} from 'react-redux'
import {StyledForm} from 'components/common/styles/base-form.styles'
import {AddBondFormProps, AddBondSchema} from './types'

type Props = {
  toggleModal(): void
}

const initialValues: AddBondFormProps = {
  name: '',
  location: '',
  description: '',
}

const AddBondForm: React.FC<FormikProps<AddBondFormProps>> = ({
  isValidating,
  isSubmitting,
}) => (
  <StyledForm>
    <FormGroup>
      <Label>Name</Label>
      <Input
        name="name"
        type="text"
        placeholder="Name"
        withLoading
        withFeedback
      />
    </FormGroup>
    <FormGroup>
      <Label>Location</Label>
      <Input
        name="location"
        type="text"
        placeholder="Location"
        withLoading
        withFeedback
      />
    </FormGroup>
    <FormGroup>
      <Label>Description</Label>
      <Input
        name="description"
        type="textarea"
        rows={8}
        placeholder="Description"
        withLoading
        withFeedback
      />
    </FormGroup>
    <FormGroup className="mb-0">
      <Submit withLoading>Submit</Submit>
      {!isValidating && isSubmitting ? (
        <div className="text-center pt-3 pb-1">
          <LoadingSpinner />
        </div>
      ) : null}
    </FormGroup>
  </StyledForm>
)

const AddBondFormOverview: FC<Props> = ({toggleModal}) => {
  const dispatch = useDispatch()
  const handleSubmit = (
    values: AddBondFormProps,
    {setSubmitting}: FormikHelpers<AddBondFormProps>,
  ): void => {
    dispatch(addBondStart({...values, setSubmitting, toggleModal}))
  }
  return (
    <Col>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={AddBondSchema}
        component={AddBondForm}
      />
    </Col>
  )
}

export default AddBondFormOverview
