import {SagaIterator} from 'redux-saga'
import {takeLatest, all, call} from 'redux-saga/effects'
import {FETCH_REFERRALS_START} from './referrals.constants'
import {FetchReferralsStartSaga} from './referrals.sagas'

export function* onFetchReferralsStart(): SagaIterator<void> {
  yield takeLatest(FETCH_REFERRALS_START, FetchReferralsStartSaga)
}

export function* referralsSagas(): SagaIterator<void> {
  yield all([call(onFetchReferralsStart)])
}
