import {put, call} from 'redux-saga/effects'
import {SagaIterator} from 'redux-saga'
import * as schema from '_redux/schema'
import log from 'loglevel'
import BondsService from 'services/api/bonds/service'
import {normalize} from 'normalizr'
import {FetchBondsStartAction} from './bonds-all-actions.types'
import {
  fetchBondsFailure,
  fetchBondsSuccess,
  setBondsMeta,
  fetchBondsSummarySuccess,
  fetchBondsSummaryFailure,
} from './bonds-all.actions'

export function* FetchBondsStartSaga({
  payload: currPage,
}: FetchBondsStartAction): SagaIterator<void> {
  const bondsService = new BondsService()
  try {
    const {
      data: {data: bonds, pages, total_bond: total},
    } = yield call([bondsService, 'fetchBonds'], currPage)
    const normalizedBonds = yield call(normalize, bonds, schema.arrayOfBonds)
    yield put(fetchBondsSuccess(normalizedBonds))
    yield put(setBondsMeta({pages, total}))
  } catch (error) {
    log.warn(error)
    yield put(fetchBondsFailure())
  }
}

export function* FetchBondsSummaryStartSaga(): SagaIterator<void> {
  const bondsService = new BondsService()
  try {
    const {data} = yield call([bondsService, 'fetchBondsSummary'])
    yield put(fetchBondsSummarySuccess(data))
  } catch (error) {
    log.warn(error)
    yield put(fetchBondsSummaryFailure())
  }
}
