import format from 'date-fns/format'
import {Bond} from '_redux/bonds/single/bonds-single.types'
import {Referral} from '_redux/referrals/referrals.types'
import {Member} from '_redux/members/members.types'
import {titleCaseName} from 'helpers/utils'
import {PlaceholderImage} from 'helpers/placeholder-image'
import {TableBodyData} from './types'

export const transformBondsForTable = (
  bonds: {bond: Bond; bondSeriesKeys: string[]}[],
): TableBodyData[] => {
  return bonds.flatMap(({bond, bondSeriesKeys}) => {
    if (!bondSeriesKeys.length) {
      return [
        {
          key: `${bond?.id}` ?? '',
          header: {
            value: `${bond?.bond_name}` ?? '',
            underlineValue: true,
            isLink: true,
            url: `/dashboard/bond-profile/${bond.id}`,
          },
          data: [
            {
              value: '-',
            },
            {
              value: '-',
            },
          ],
        },
      ]
    }
    return bondSeriesKeys.map(seriesKey => ({
      key: `${bond?.id}${seriesKey}` ?? '',
      header: {
        value: `${bond?.bond_name} - (${seriesKey})` ?? '',
        underlineValue: true,
        isLink: true,
        url: `/dashboard/bond-profile/${bond.id}`,
      },
      data: [
        {
          value: `£${
            bond?.series?.[seriesKey]?.unit_price.toLocaleString() ?? '-'
          }`,
        },
        {
          value: `£${
            bond?.series?.[seriesKey].returns.toLocaleString() ?? '-'
          }`,
        },
      ],
    }))
  })
}

export const transformReferralsForTable = (
  referrals: Referral[],
): TableBodyData[] => {
  return referrals.map(referral => ({
    key: referral?.id ?? '',
    header: {
      value: referral?.id.toString() ?? '',
    },
    data: [
      {value: referral?.name ?? '-', underlineValue: true},
      {value: titleCaseName(referral?.status)},
    ],
    disabled: referral?.status !== 'confirmed',
  }))
}

export const transformMembersForTable = (
  members: Member[],
): TableBodyData[] => {
  return members.map(member => ({
    key: member?.id ?? '',
    header: {
      value: PlaceholderImage,
      isImage: true,
    },

    data: [
      {value: member?.name ?? '-', underlineValue: true},
      {
        value: format(
          // Date comes in mm-dd-yy format instead of dd-mm-yy
          new Date(
            `${member?.status.substring(3, 5)}/${member?.status.substring(
              0,
              2,
            )}/${member?.status.substring(6)} 00:00:00`,
          ),
          'd MMMM yyyy',
        ),
      },
    ],
  }))
}
