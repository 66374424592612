import React, {FC} from 'react'
import {Row, Col, Card, CardTitle} from 'reactstrap'
import AdminLayout from 'components/layouts/admin.component'
import ActionBar from 'components/common/action-bar.component'
import {IconContext} from 'react-icons'
import {FaEdit, FaSave} from 'react-icons/fa'
import {theme} from 'AppTheme'
import SettingsTable from './settings-table.component'
import {StyledTableRow} from './styles'
import AccessLevelTable from './access-level-table.component'
import SystemTable from './system-table.component'

const editIconValues = {
  color: theme.colors.primary,
  size: '1.4em',
  style: {verticalAlign: 'middle', marginBottom: '4px'},
}

const SettingsAccess: FC = () => {
  return (
    <AdminLayout pageTitle="Settings & Access">
      <Row>
        <ActionBar headerText="Settings & Access" />
      </Row>
      <StyledTableRow className="py-3">
        <Col className="px-0" sm="4">
          <Card body>
            <CardTitle className="font-weight-bold">Settings</CardTitle>
            <div>
              <SettingsTable />
            </div>
          </Card>
        </Col>
        <Col className="pr-0" sm="4">
          <Card body>
            <CardTitle className="font-weight-bold">
              <span>Access level</span>
              <IconContext.Provider value={editIconValues}>
                <div
                  role="button"
                  // onClick={toggleOpenEditModal}
                  className="d-inline text-secondary float-right"
                >
                  <span className="pr-2">Edit</span>
                  <FaEdit />
                </div>
              </IconContext.Provider>
            </CardTitle>
            <div>
              <AccessLevelTable />
            </div>
          </Card>
        </Col>
        <Col className="pr-0" sm="4">
          <Card body>
            <CardTitle className="font-weight-bold">
              <span>System</span>
              <IconContext.Provider value={editIconValues}>
                <div
                  role="button"
                  // onClick={toggleOpenEditModal}
                  className="d-inline text-secondary float-right"
                >
                  <span className="pr-2">Backup now</span>
                  <FaSave />
                </div>
              </IconContext.Provider>
            </CardTitle>
            <div>
              <SystemTable />
            </div>
          </Card>
        </Col>
      </StyledTableRow>
    </AdminLayout>
  )
}

export default SettingsAccess
