/* eslint-disable max-lines-per-function */
import React from 'react'
import {FormikProps} from 'formik'
import {StyledForm} from 'components/common/styles/base-form.styles'
import {FormGroup, Label, Row, Col} from 'reactstrap'
import {Input, Submit} from 'formstrap'
import LoadingSpinner from 'components/common/loading-spinner.component'
import {EditBondSeriesFormProps} from './types'

// const today = new Date().toISOString().substr(0, 10)

const EditBondSeriesForm: React.FC<FormikProps<EditBondSeriesFormProps>> = ({
  isValidating,
  isSubmitting,
}) => (
  <StyledForm>
    <FormGroup>
      <Label>Series Name</Label>
      <Input
        name="name"
        type="text"
        placeholder="Name"
        withLoading
        withFeedback
      />
    </FormGroup>
    <Row form>
      <Col sm={4}>
        <FormGroup>
          <Label>On Offer</Label>
          <Input
            name="offer"
            type="number"
            placeholder="Ex. 20,000"
            withLoading
            withFeedback
          />
        </FormGroup>
      </Col>
      <Col sm={4}>
        <FormGroup>
          <Label>Tenure</Label>
          <Input
            name="tenor"
            type="number"
            placeholder="Ex. 20"
            withLoading
            withFeedback
          />
        </FormGroup>
      </Col>
      <Col sm={4}>
        <FormGroup>
          <Label>Interest rate</Label>
          <Input
            name="interest"
            type="number"
            placeholder="Ex. 3.5"
            withLoading
            withFeedback
          />
        </FormGroup>
      </Col>
    </Row>
    <Row form>
      <Col sm={4}>
        <FormGroup>
          <Label>Units Available</Label>
          <Input
            name="units"
            type="number"
            placeholder="Ex. 20,000"
            withLoading
            withFeedback
          />
        </FormGroup>
      </Col>
      <Col sm={4}>
        <FormGroup>
          <Label>Min Units</Label>
          <Input
            name="min_unit"
            type="number"
            placeholder="Ex. 20,000"
            withLoading
            withFeedback
          />
        </FormGroup>
      </Col>
      <Col sm={4}>
        <FormGroup>
          <Label>Max Units</Label>
          <Input
            name="max_unit"
            type="number"
            placeholder="Ex. 20"
            withLoading
            withFeedback
          />
        </FormGroup>
      </Col>
    </Row>
    <Row form>
      <Col sm={4}>
        <FormGroup>
          <Label>Unit Price</Label>
          <Input
            name="unit_price"
            type="number"
            placeholder="Ex. 2,000"
            withLoading
            withFeedback
          />
        </FormGroup>
      </Col>
      <Col sm={4}>
        <FormGroup>
          <Label>Interest paid</Label>
          <Input
            name="interest_paid"
            type="select"
            placeholder="Annually"
            withLoading
            withFeedback
          >
            <option value={1}>Monthly</option>
            <option value={2}>Quarterly</option>
            <option value={3}>Annually</option>
          </Input>
        </FormGroup>
      </Col>
      <Col sm={4}>
        <FormGroup>
          <Label>Security</Label>
          <Input
            name="security"
            type="select"
            placeholder="Legal Charge"
            withLoading
            withFeedback
          >
            <option value="Legal Charge">Legal Charge</option>
          </Input>
        </FormGroup>
      </Col>
    </Row>
    <Row form>
      <Col sm={4}>
        <FormGroup>
          <Label>Start Date</Label>
          <Input
            name="start_date"
            type="date"
            placeholder="27/06/2022"
            withLoading
            withFeedback
          />
        </FormGroup>
      </Col>
      <Col sm={4}>
        <FormGroup>
          <Label>End Date</Label>
          <Input
            name="close_date"
            type="date"
            placeholder="27/07/2022"
            withLoading
            withFeedback
          />
        </FormGroup>
      </Col>
      <Col sm={4}>
        <FormGroup>
          <Label>Maturity Date</Label>
          <Input
            name="maturity_date"
            type="date"
            placeholder="27/06/2023"
            withLoading
            withFeedback
          />
        </FormGroup>
      </Col>
    </Row>
    <FormGroup tag="fieldset" className="mb-0" row>
      <legend className="col-form-label py-0 pl-3 font-weight-bold">
        Total Raised
      </legend>
      <Col sm={6}>
        <FormGroup>
          <Label>Estimate</Label>
          <Input
            name="estimate"
            type="number"
            placeholder="Ex. 2,000"
            withLoading
            withFeedback
          />
        </FormGroup>
      </Col>
      <Col sm={6}>
        <FormGroup>
          <Label>Actual</Label>
          <Input
            name="actual"
            type="number"
            placeholder="Ex. 3,000"
            withLoading
            withFeedback
          />
        </FormGroup>
      </Col>
    </FormGroup>
    <FormGroup tag="fieldset" className="mb-0" row>
      <legend className="col-form-label py-0 pl-3 font-weight-bold">
        Interest Payment
      </legend>
      <Col sm={6}>
        <FormGroup>
          <Label>Annual</Label>
          <Input
            name="annual"
            type="number"
            placeholder="Ex. 2,000"
            withLoading
            withFeedback
          />
        </FormGroup>
      </Col>
      <Col sm={6}>
        <FormGroup>
          <Label>Life Of Issue</Label>
          <Input
            name="life_of_issue"
            type="number"
            placeholder="Ex. 3,000"
            withLoading
            withFeedback
          />
        </FormGroup>
      </Col>
    </FormGroup>
    <FormGroup>
      <Label>Opportunity type</Label>
      <Input
        name="opportunity_type"
        type="select"
        placeholder="Charity"
        withLoading
        withFeedback
      >
        <option value="charity">Charity</option>
        <option value="investment">Investment</option>
      </Input>
    </FormGroup>
    <FormGroup className="mb-0">
      <Submit withLoading>Submit</Submit>
      {!isValidating && isSubmitting ? (
        <div className="text-center pt-3 pb-1">
          <LoadingSpinner />
        </div>
      ) : null}
    </FormGroup>
  </StyledForm>
)

export default EditBondSeriesForm
