import {combineReducers} from 'redux'
import {DashboardModelMeta} from 'components/common/types/dashboard-models.types'
import {
  FETCH_REFERRALS_START,
  FETCH_REFERRALS_SUCCESS,
  FETCH_REFERRALS_FAILURE,
  SET_REFERRALS_META,
} from './referrals.constants'
import {
  ReferralsDictionary,
  ReferralsIdList,
  ReferralsRequestingStateActions,
  ReferralsStateActions,
} from './referrals.types'
import {SetReferralsMetaAction} from './referrals-actions.types'

function referralsById(
  state: ReferralsDictionary = {},
  {type, payload}: ReferralsStateActions,
): ReferralsDictionary {
  switch (type) {
    case FETCH_REFERRALS_START: {
      if (typeof payload === 'string' && payload === 1) {
        return {}
      }
      return state
    }
    case FETCH_REFERRALS_SUCCESS: {
      if (typeof payload !== 'number') {
        return {...state, ...payload.entities.referrals}
      }
      return state
    }
    default:
      return state
  }
}

function allReferralsIds(
  state: ReferralsIdList = [],
  {type, payload}: ReferralsStateActions,
): ReferralsIdList {
  switch (type) {
    case FETCH_REFERRALS_START: {
      if (typeof payload === 'string' && payload === 1) {
        return []
      }
      return state
    }
    case FETCH_REFERRALS_SUCCESS: {
      if (typeof payload !== 'number') {
        const stateCopy = [...state, ...payload.result]
        return Array.from(new Set(stateCopy))
      }
      return state
    }
    default:
      return state
  }
}

function referralsMeta(
  state: DashboardModelMeta = {pages: 0},
  {type, payload}: SetReferralsMetaAction,
): DashboardModelMeta {
  switch (type) {
    case SET_REFERRALS_META:
      return payload
    default:
      return state
  }
}

function referralsRequesting(
  state = true,
  {type}: ReferralsRequestingStateActions,
): boolean {
  switch (type) {
    case FETCH_REFERRALS_START:
      return true
    case FETCH_REFERRALS_SUCCESS:
    case FETCH_REFERRALS_FAILURE:
      return false
    default:
      return state
  }
}

const referralsReducer = combineReducers({
  byId: referralsById,
  allIds: allReferralsIds,
  meta: referralsMeta,
  requesting: referralsRequesting,
})

export default referralsReducer
