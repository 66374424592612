import {DashboardModelMeta} from 'components/common/types/dashboard-models.types'
import {
  FETCH_BONDS_START,
  FETCH_BONDS_SUCCESS,
  FETCH_BONDS_FAILURE,
  SET_BONDS_META,
  FETCH_BONDS_SUMMARY_START,
  FETCH_BONDS_SUMMARY_SUCCESS,
  FETCH_BONDS_SUMMARY_FAILURE,
} from '../bonds.constants'
import {BondsActionTypes} from '../bonds.types'
import {NormalizedBonds, BondsSummary} from './bonds-all.types'

export function fetchBondsStart(page: number): BondsActionTypes {
  return {
    type: FETCH_BONDS_START,
    payload: page,
  }
}

export function fetchBondsSuccess(data: NormalizedBonds): BondsActionTypes {
  return {
    type: FETCH_BONDS_SUCCESS,
    payload: data,
  }
}

export function fetchBondsFailure(): BondsActionTypes {
  return {
    type: FETCH_BONDS_FAILURE,
  }
}

export function setBondsMeta(meta: DashboardModelMeta): BondsActionTypes {
  return {
    type: SET_BONDS_META,
    payload: meta,
  }
}

export function fetchBondsSummaryStart(): BondsActionTypes {
  return {
    type: FETCH_BONDS_SUMMARY_START,
  }
}

export function fetchBondsSummarySuccess(data: BondsSummary): BondsActionTypes {
  return {
    type: FETCH_BONDS_SUMMARY_SUCCESS,
    payload: data,
  }
}

export function fetchBondsSummaryFailure(): BondsActionTypes {
  return {
    type: FETCH_BONDS_SUMMARY_FAILURE,
  }
}
