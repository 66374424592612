import {put, call} from 'redux-saga/effects'
import {SagaIterator} from 'redux-saga'
import * as schema from '_redux/schema'
import log from 'loglevel'
import ReferralsService from 'services/api/referrals/service'
import {normalize} from 'normalizr'
import {FetchReferralsStartAction} from './referrals-actions.types'
import {
  fetchReferralsFailure,
  fetchReferralsSuccess,
  setReferralsMeta,
} from './referrals.actions'

export function* FetchReferralsStartSaga({
  payload: currPage,
}: FetchReferralsStartAction): SagaIterator<void> {
  const referralsService = new ReferralsService()
  try {
    const {
      data: {data: referrals, pages},
    } = yield call([referralsService, 'fetchReferrals'], currPage)
    const normalizedReferrals = yield call(
      normalize,
      referrals,
      schema.arrayOfReferrals,
    )
    yield put(fetchReferralsSuccess(normalizedReferrals))
    yield put(setReferralsMeta({pages}))
  } catch (error) {
    log.warn(error)
    yield put(fetchReferralsFailure())
  }
}
