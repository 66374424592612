/* eslint-disable max-lines-per-function */
import React, {FC, useState} from 'react'
import {NavItem, NavLink, TabContent, TabPane, Row, Col} from 'reactstrap'
import {FormikHelpers, Formik} from 'formik'
import {useDispatch} from 'react-redux'
import {
  editBondDetailsStart,
  editBondSeriesStart,
  createBondSeriesStart,
} from '_redux/bonds/single/bonds-single.actions'
import {Bond} from '_redux/bonds/single/bonds-single.types'
import {StyledEditBondNav} from './styles'
import {
  EditBondDetailsFormProps,
  EditBondDetailsSchema,
  EditBondSeriesFormProps,
  EditBondSeriesSchema,
  CreateBondSeriesFormProps,
  CreateBondSeriesSchema,
} from './types'
import EditBondDetailsForm from './edit-details-form.component'
import EditBondSeriesForm from './edit-series-form.component'
import CreateBondSeriesForm from './create-series-form.component'

type Props = {
  toggleModal(): void
  bond: Bond
  currentSeries: string
}

const initialCreateBondSeriesValues: CreateBondSeriesFormProps = {
  name: '',
  offer: 0,
  tenor: 0,
  interest: 0,
  units: 0,
  min_unit: 0,
  max_unit: 0,
  unit_price: 0,
  interest_paid: 3,
  security: 'Legal Charge',
  start_date: '',
  close_date: '',
  maturity_date: '',
  estimate: 0,
  actual: 0,
  annual: 0,
  life_of_issue: 0,
  opportunity_type: 'charity',
}

const EditBondProfileTabs: FC<Props> = ({toggleModal, bond, currentSeries}) => {
  const [activeTab, setActiveTab] = useState('1')
  const [initialEditBondSeriesValues] = useState<EditBondSeriesFormProps>(
    () => {
      if (bond.series[currentSeries]) {
        return {
          name: currentSeries,
          offer: bond.series[currentSeries]?.offer,
          tenor: bond.series[currentSeries]?.tenor,
          interest: bond.series[currentSeries]?.interest,
          units: bond.series[currentSeries]?.units,
          min_unit: bond.series[currentSeries]?.min_unit,
          max_unit: bond.series[currentSeries]?.max_unit,
          unit_price: bond.series[currentSeries]?.unit_price,
          interest_paid: bond.series[currentSeries]?.interest_paid,
          security: bond.series[currentSeries]?.security,
          start_date: new Date(bond.series[currentSeries]?.start_date)
            .toISOString()
            .substr(0, 10),
          close_date: new Date(bond.series[currentSeries]?.close_date)
            .toISOString()
            .substr(0, 10),
          maturity_date: new Date(bond.series[currentSeries]?.maturity_date)
            .toISOString()
            .substr(0, 10),
          estimate: bond.series[currentSeries]?.total_raised.estimate,
          actual: bond.series[currentSeries]?.total_raised.actual,
          annual: bond.series[currentSeries]?.interest_payment.annual,
          life_of_issue:
            bond.series[currentSeries]?.interest_payment.life_of_issue,
          opportunity_type: bond.series[currentSeries]?.opportunity_type,
        }
      }
      return initialCreateBondSeriesValues
    },
  )

  const toggle = (tab: string): void => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const initialBondDetailsValues: EditBondDetailsFormProps = {
    name: bond.bond_name,
    location: bond.location,
    description: bond.description,
  }

  const dispatch = useDispatch()
  const handleEditBondDetailsSubmit = (
    values: EditBondDetailsFormProps,
    {setSubmitting}: FormikHelpers<EditBondDetailsFormProps>,
  ): void => {
    dispatch(
      editBondDetailsStart({
        ...values,
        bond_id: bond.id,
        image: bond.image,
        status: 1,
        setSubmitting,
        toggleModal,
      }),
    )
  }

  const handleEditBondSeriesSubmit = (
    values: EditBondSeriesFormProps,
    {setSubmitting}: FormikHelpers<EditBondSeriesFormProps>,
  ): void => {
    dispatch(
      editBondSeriesStart({
        ...values,
        id: bond.series[currentSeries].id,
        bond_id: bond.series[currentSeries].bond_id,
        series: 0,
        status: bond.series[currentSeries].status,
        setSubmitting,
        toggleModal,
      }),
    )
  }

  const handleCreateBondSeriesSubmit = (
    values: CreateBondSeriesFormProps,
    {setSubmitting}: FormikHelpers<CreateBondSeriesFormProps>,
  ): void => {
    dispatch(
      createBondSeriesStart({
        ...values,
        bond_id: currentSeries
          ? bond.series[currentSeries].bond_id
          : `${bond.id}`,
        series: 0,
        status: 0,
        setSubmitting,
        toggleModal,
      }),
    )
  }

  const renderNavItems = () => {
    return ['Details', 'Series', 'Create Series'].map((header, index) => {
      return (
        <NavItem key={header}>
          <NavLink
            className={`${activeTab === `${index + 1}` ? 'active' : ''}`}
            onClick={() => {
              toggle(`${index + 1}`)
            }}
            disabled={!currentSeries && header === 'Series'}
          >
            {header}
          </NavLink>
        </NavItem>
      )
    })
  }

  return (
    <div>
      <StyledEditBondNav className="mb-3" tabs>
        {renderNavItems()}
      </StyledEditBondNav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row>
            <Col>
              <Formik
                initialValues={initialBondDetailsValues}
                onSubmit={handleEditBondDetailsSubmit}
                validationSchema={EditBondDetailsSchema}
                component={EditBondDetailsForm}
              />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2">
          <Row>
            <Col>
              <Formik
                initialValues={initialEditBondSeriesValues}
                onSubmit={handleEditBondSeriesSubmit}
                validationSchema={EditBondSeriesSchema}
                component={EditBondSeriesForm}
              />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="3">
          <Row>
            <Col>
              <Formik
                initialValues={initialCreateBondSeriesValues}
                onSubmit={handleCreateBondSeriesSubmit}
                validationSchema={CreateBondSeriesSchema}
                component={CreateBondSeriesForm}
              />
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </div>
  )
}

export default EditBondProfileTabs
