import {DashboardModelMeta} from 'components/common/types/dashboard-models.types'
import {
  FETCH_MEMBERS_START,
  FETCH_MEMBERS_SUCCESS,
  FETCH_MEMBERS_FAILURE,
  SET_MEMBERS_META,
} from './members.constants'
import {NormalizedMembers, MembersActionTypes} from './members.types'

export function fetchMembersStart(page: number): MembersActionTypes {
  return {
    type: FETCH_MEMBERS_START,
    payload: page,
  }
}

export function fetchMembersSuccess(
  data: NormalizedMembers,
): MembersActionTypes {
  return {
    type: FETCH_MEMBERS_SUCCESS,
    payload: data,
  }
}

export function fetchMembersFailure(): MembersActionTypes {
  return {
    type: FETCH_MEMBERS_FAILURE,
  }
}

export function setMembersMeta(meta: DashboardModelMeta): MembersActionTypes {
  return {
    type: SET_MEMBERS_META,
    payload: meta,
  }
}
